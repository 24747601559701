<template>
	<div class="page-not-found">
		<div class="container" v-bind:class="[isActive ? 'active' : 'not-active']">
			<div class="wrap">
				<div class="wrap_buttons">
                    <router-link :to="{name: 'explore'}" class="home tetriatary_btn">Home</router-link>
                    <a href="mailto:admin@psychurch.org?subject=Bug%20Report" target="_blank" class="tetriatary_btn">Report</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: '404',
	data() {
		return {
			isActive: true
		};
	},
	created() {
		// setInterval(() => {
		//     this.isActive = !this.isActive;
		// }, 1000);
	}
};
</script>
